import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  private title: string = 'app';
  private logoUrl: string = "/images/multiarray_logo.png";
  ngOnInit() {
    setInterval(() => {
      this.logoUrl = localStorage.getItem("AgencyLogoUrl");
    }, 10000);
  }
}
