import { LoaderService } from './../service/loaderservice/loaderservice.service';
import { HomeService } from './../service/homeservice.service';
import { ServiceResponse } from './../serviceresponse';
import { Router, RouterModule } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { IHomeModelList } from './../model/homemodel';
import { Injectable, Inject, PLATFORM_ID, Injector } from "@angular/core";
import {
  Http,
  Headers,
  Response,
  RequestOptions,
  RequestMethod
} from "@angular/http";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  private storageStatisticList: IHomeModelList[];
  constructor
    (
      private _http: Http,
      private HTTP: HttpClient,
      private _router: Router,
      private route: ActivatedRoute,
      private _service: HomeService,
      private _loaderService: LoaderService,
      @Inject(PLATFORM_ID) private platformID: Object
    ) { }
  ngOnInit() {
    this._loaderService.show();

    this._service.GetAgencyLogoUrl
      (
        this.route.snapshot.paramMap.get("databaseName"),
        this.route.snapshot.paramMap.get("sessionID"),
        0,
        400,
        400
      )
      .subscribe((data: ServiceResponse<string>) => {
        if (!!data && !data.isError) {
          if (!!data.response) {
            localStorage.setItem("AgencyLogoUrl", data.response);
          }
          else {
            localStorage.setItem("AgencyLogoUrl", "/images/multiarray_logo.png");
          }
        }
        else {
          alert(data.message);
        }
      });
    this._service.GetStorageStatisticsDetails
      (
        this.route.snapshot.paramMap.get("databaseName"),
        this.route.snapshot.paramMap.get("sessionID")
      )
      .subscribe((data: ServiceResponse<IHomeModelList[]>) => {
        this._loaderService.hide();
        if (!!data && !data.isError) {
          this.storageStatisticList = data.response;
        }
        else{
          alert(data.message);
        }
      })
  }
}
