import { map ,catchError} from 'rxjs/operators';
import { IHomeModelList } from './../model/homemodel';
import { Observable } from "rxjs";
import { Injectable, Inject, PLATFORM_ID, Injector } from "@angular/core";
import {
  Http,
  Headers,
  Response,
  RequestOptions,
  RequestMethod
} from "@angular/http";
import { HttpClient } from '@angular/common/http'
import { ServiceResponse } from '../serviceresponse';




@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private _http: Http, private HTTP: HttpClient, @Inject(PLATFORM_ID) private platformID: Object) { }

  GetAgencyLogoUrl
  (
      databaseName: string
      , sessionID: string
      , imageSize: number
      , width: number
      , height: number
  ): Observable<ServiceResponse<string>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Home/GetAgencyLogoUrl", {
        databaseName: databaseName,
        sessionID: sessionID,
        imageSize: imageSize,
        width: width,
        height: height
      }, options)      
      .pipe(
        map(res => {
          if (!res) {
            throw new Error('Value expected!');
          }
          return res.json();
        }),
        catchError(err => Observable.throw(err.json().error || "Server Error"))
      );
  }
  GetStorageStatisticsDetails(
    databaseName: string,
    sessionID: string    
    ): Observable<ServiceResponse<IHomeModelList[]>> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http
      .post("api/Home/GetStorageStatisticsDetails", {
        databaseName: databaseName,
        sessionID: sessionID
      }, options)      
      .pipe(
        map(res => {
          if (!res) {
            throw new Error('Value expected!');
          }
          return res.json();
        }),
        catchError(err => Observable.throw(err.json().error || "Server Error"))
      );
  }
}
